import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, dayMonthDateFormat } from "../../../constants"
import moment from "moment";
import { useSnackbar } from 'notistack';
import config from "../../../config";
import { getLocalStorageInfo,format_Commas } from "../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../utils/globalApiServices';
import { PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus } from '../../../utils'
import { specificKey,specificMessTsn,specificMessCsn } from '../../camo';

function AddEditFleetAD({ toggleModalFn, fleetData, modeType, getResponseBack }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(fleetData)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [applicability, setApplicability] = useState([])
    const [egineFamily, setEngineFamily] = useState([])
    const [uploadFile, setUploadFile] = useState(false)

    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
    useEffect(() => {
        getEngineFamily()
        if (data?.engine_family) {
            onChangeEngineFamily('', data?.engine_family)
        }
        getApplicability()
    }, [])


    const onFieldChange = (keyParam, value, extra) => {
        if (keyParam === "attachments") {
            if (Array.isArray(value)) {
                const updatedAttachments = [...(data?.attachments || []), ...value];
                setData({ ...data, [keyParam]: updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", value);
            }
        } else {
            setData({ ...data, [keyParam]: value });
        }
    }
    const onChangeEngineFamily = (keyParam, value) => {
        if (keyParam === 'engine_family' && !value) {
            setData({ ...data, ['engine_family']: '', ['applicability']: '' });
            getApplicability()
        } else {
            globalGetService(`camo/engine_dropdown/${value?.id}/`,)
                .then(response => {
                    if (checkApiStatus(response)) {
                        setData({
                            ...data,
                            [keyParam]: value,
                            applicability: response?.data?.engine_type
                        });
                        setApplicability(response?.data?.engine_type)
                    } else {
                        setData({ ...data, [keyParam]: value });
                    }
                })
        }
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }
    const getEngineFamily = () => {
        globalGetService(`camo/engine_dropdown/`)
            .then((response) => {
                if (response.status === 200) {
                    setEngineFamily(response?.data?.engine_family)
                }
            })
    }
    const getApplicability = () => {
        globalGetService(`console/engine-types/`)
            .then((response) => {
                if (response.status === 200) {
                    setApplicability(response?.data?.data?.engineTypes)
                }
            })
    }
    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!smbcInstance) {
            validationInputs = {
                ...validationInputs,
                ata: data?.ata ? '' : "Please Enter ATA",
                ad_no: data?.ad_no ? '' : "Please Enter AD Refrence Number",
                description: data?.description ? '' : "Please Enter Description",
                effective_date: data?.effective_date ? '' : "Please Enter Effective Date",
                type: data?.type ? '' : "Please Enter Type",
                workorder: data?.ad_compliance_status === "NOT APPLICABLE" || data?.workorder ? '' : "Please Enter Workorder",
                ref_documents: data?.ref_documents ? '' : "Please Enter Reference Documents",
                ad_compliance_dfp: data?.ad_compliance_dfp ? '' : "Please Enter Select DFP"
            }
        }
        if (!data?.ad_compliance_status === "NOT APPLICABLE") {
            if (!['NA', 'N/A', 'Unknown', 'UNK'].includes(data?.ad_compliance_tsn?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data?.ad_compliance_tsn)) {
                validationInputs.ad_compliance_tsn = "Allow Keywords NA, N/A,Unknown,or UNK,or numeric values up to 2 decimal place";
            }
            if (!['NA', 'N/A', 'Unknown', 'UNK'].includes(data?.ad_compliance_csn?.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data?.ad_compliance_csn)) {
                validationInputs.ad_compliance_csn = "Allow Keywords NA, N/A,Unknown,or UNK,or numeric values";
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/fleet_ad/${payload.id}/`, { ...payload, asset_type_id: 2, applicability: payload?.applicability ? payload?.applicability?.map(item => item?.id).join(',') : '',ad_compliance_tsn: payload.ad_compliance_tsn ? payload?.ad_compliance_tsn : null,ad_compliance_csn: payload.ad_compliance_csn ? payload?.ad_compliance_csn.toString().replace(",", "") : null  })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/fleet_ad/`, { ...payload, asset_type_id: 2, applicability: payload?.applicability ? payload?.applicability?.map(item => item?.id).join(',') : '',ad_compliance_tsn: payload.ad_compliance_tsn ? payload?.ad_compliance_tsn : null,ad_compliance_csn: payload.ad_compliance_csn ? payload?.ad_compliance_csn.toString().replace(",", "") : null  })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }
    const importFile = (item) => {
        var formData = new FormData();
        Object.keys(data?.attachments?.length >0 && data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", item?.id)
            formData.append("[" + key + "]module_type_id", 14)
            formData.append("[" + key + "]asset_type_id", 2)
        })
        globalFileUploadService(`camo/attachment/upload/?fleet=ad`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveUploadFile = (files) => {
        if (!files?.id) {
            setData({ ...data, attachments: data?.attachments.filter(item => item.name !== files.name) });
        } else {
            globalDeleteService(`camo/attachment/${files?.id}/delete/?fleet=ad`)
                .then((response) => {
                    if (response.data?.statusCode === 200) {
                        setData({ ...data, attachments: data?.attachments.filter(item => item.name !== files.name) })
                        getResponseBack()
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                    }
                })
        }
    }
console.log("error",error)
    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Edit fleet AD" : "Add Fleet AD"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="ATA Chapter"
                                margin='normal'
                                id="ata"
                                fullWidth
                                value={data?.ata}
                                required={!smbcInstance}
                                error={error?.ata ? true : false}
                                inputProps={{maxLength:200}}
                                InputLabelProps={{ shrink: true }}
                                helperText={error?.ata ? error?.ata : ""}
                                onChange={(e) => { onFieldChange("ata", e.target.value); onRestErrorKey("ata") }}
                            />
                        </Grid>
                        {smbcInstance ? <>
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    margin='normal'
                                    label="FAA AD"
                                    id="faa_ad"
                                    inputProps={{maxLength:256}}
                                    fullWidth
                                    value={data?.faa_ad}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange("faa_ad", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    margin='normal'
                                    label="EASA AD"
                                    id="easa_ad"
                                    inputProps={{maxLength:256}}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={data?.easa_ad}
                                    onChange={(e) => onFieldChange("easa_ad", e.target.value)}
                                />
                            </Grid>
                        </>
                            :
                            <>
                                <Grid item xs={12} md={3} sm={3}>
                                    <TextField
                                        variant="outlined"
                                        margin='normal'
                                        label="AD Reference Number"
                                        id="ad_no"
                                        fullWidth
                                        value={data?.ad_no}
                                        required={!smbcInstance}
                                        error={error?.ad_no ? true : false}
                                        helperText={error?.ad_no ? error?.ad_no : ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { onFieldChange("ad_no", e.target.value); onRestErrorKey("ata") }}
                                    />

                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <Autocomplete
                                        id="ad_type"
                                        options={applicability || []}
                                        getOptionLabel={(option) => option?.name}
                                        value={data?.applicability || []}
                                        margin='normal'
                                        multiple={true}
                                        onChange={(e, value) => onFieldChange("applicability", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Applicability"
                                                margin='normal'
                                                placeholder="Select Applicability"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                label="Issuing Authority"
                                variant="outlined"
                                margin='normal'
                                fullWidth
                                id="issuing_authority"
                                inputProps={{maxLength:200}}
                                value={data?.issuing_authority}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("issuing_authority", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {smbcInstance && <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="FAA Superseded"
                                margin='normal'
                                fullWidth
                                name="faa_superseded"
                                inputProps={{maxLength:256}}
                                value={data?.faa_superseded}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("faa_superseded", e.target.value)}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="EASA Superseded"
                                name="easa_superseded"
                                margin='normal'
                                inputProps={{maxLength:256}}
                                fullWidth
                                value={data?.easa_superseded}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("easa_superseded", e.target.value)}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Related Document"
                                margin='normal'
                                name="related_document"
                                inputProps={{maxLength:256}}
                                fullWidth
                                value={data?.related_document}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("related_document", e.target.value)}

                            />
                        </Grid>
                    </Grid>}
                    {smbcInstance &&
                        <Grid spacing={1} container>
                            <Grid item xs={12} md={3} sm={3}>
                                <Autocomplete
                                    id="engine_family"
                                    options={egineFamily || []}
                                    getOptionLabel={(option) => option?.name}
                                    value={data?.engine_family || []}
                                    margin='normal'
                                    onChange={(e, value) => onChangeEngineFamily("engine_family", value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={
                                                <span style={{ verticalAlign: 'super' }}>
                                                    Engine Family
                                                    <Tooltip placement='right' title="Select Engine Family to apply all Engine Types under the family to Applicability" arrow>
                                                        <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                    </Tooltip>
                                                </span>
                                            }
                                            margin='normal'
                                            placeholder="Select Engine Family"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={9} sm={9}>
                                <Autocomplete
                                    id="ad_type"
                                    options={applicability || []}
                                    getOptionLabel={(option) => option?.name}
                                    value={data?.applicability || []}
                                    margin='normal'
                                    multiple={true}
                                    disableCloseOnSelect={true}
                                    onChange={(e, value) => onFieldChange("applicability", value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Applicability"
                                            margin='normal'
                                            placeholder="Select Applicability"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                name="description"
                                margin='normal'
                                fullWidth
                                multiline
                                minRows={5}
                                required={!smbcInstance}
                                value={data?.description}
                                InputLabelProps={{ shrink: true }}
                                error={error?.description ? true : false}
                                helperText={error?.description ? error?.description : ""}
                                onChange={(e) => { onFieldChange("description", e.target.value); onRestErrorKey("description") }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="ad_type"
                                options={['Engine']}
                                getOptionLabel={option => option}
                                value={data?.ad_type || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("ad_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="AD Type"
                                        margin='normal'
                                        placeholder="Select AD Type"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Effectivity"
                                name="effectivity"
                                id="effectivity"
                                fullWidth
                                margin='normal'
                                value={data?.effectivity}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("effectivity", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="SB Number"
                                id="sb_number"
                                fullWidth
                                margin='normal'
                                inputProps={{maxLength:200}}
                                error={error?.sb_number ? true : false}
                                InputLabelProps={{ shrink: true }}
                                helperText={error?.sb_number ? error?.sb_number : ""}
                                value={data?.sb_number}
                                onChange={(e) => { onFieldChange("sb_number", e.target.value); onRestErrorKey("sb_number") }}

                            />
                        </Grid>
                        {smbcInstance ?
                            <>
                                <Grid item xs={12} md={3} sm={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="FAA Effective Date"
                                            inputVariant="outlined"
                                            margin='normal'
                                            format={dayMonthDateFormat}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, "years")}
                                            maxDate={moment().add(2, "years")}
                                            error={false}
                                            helperText={""}
                                            value={data?.faa_effective_date || null}
                                            onPaste={(event) => event.preventDefault()}
                                            onChange={(data) => onFieldChange("faa_effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="EASA Effective Date"
                                            inputVariant="outlined"
                                            format={dayMonthDateFormat}
                                            fullWidth
                                            margin='normal'
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, "years")}
                                            maxDate={moment().add(2, "years")}
                                            error={false}
                                            helperText={""}
                                            onPaste={(event) => event.preventDefault()}
                                            value={data?.easa_effective_date || null}
                                            onChange={(data) => onFieldChange("easa_effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} md={3} sm={3} >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        label="Effective Date"
                                        id="effective_date"
                                        margin='normal'
                                        inputVariant="outlined"
                                        required={!smbcInstance}
                                        format={dayMonthDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, "years")}
                                        error={error?.effective_date ? true : false}
                                        helperText={error?.effective_date ? error?.effective_date : ""}
                                        value={data?.effective_date || null}
                                        onPaste={(event) => event.preventDefault()}
                                        onChange={(data) => { onFieldChange("effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onRestErrorKey("effective_date") }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>}
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="A/C Comp"
                                id="ac_comp"
                                inputProps={{maxLength:200}}
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data?.ac_comp}
                                onChange={(e) => onFieldChange("ac_comp", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="ad_compliance_status"
                                options={['OPEN', 'COMPLIED', 'PARTIALLY COMPLIED', 'NOT APPLICABLE']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.ad_compliance_status || null}
                                onChange={(e, value) => onFieldChange("ad_compliance_status", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Compliance Status"
                                        placeholder="Select Compliance Status"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        AD Compliance TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 99999.99</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="ad_compliance_tsn"
                                fullWidth
                                margin='normal'
                                inputProps={{maxLength:regexConstants.alphanumespcespclchar.test(data?.ad_compliance_tsn) && 10}}
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={regexConstants.alphanumespcespclchar.test(data?.ad_compliance_tsn) ? data?.ad_compliance_tsn : format_Commas(data?.ad_compliance_tsn.replace(/[^0-9.]/g, '')) }
                                error={error.ad_compliance_tsn ? true : false}
                                helperText={error.ad_compliance_tsn ? error.ad_compliance_tsn : ""}
                                o	onChange={(e) => {
									const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
									const numericValue = value.replace(/[^\d.]/g, '');
									const dotCount = (numericValue.match(/\./g) || []).length;
									const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
									if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
										onFieldChange("ad_compliance_tsn", e.target.value);
                                         setError({...error,ad_compliance_tsn:e.target.value  === (null || undefined || "")  ? "" : !specificKey.includes(e.target.value.toUpperCase()) && specificMessTsn})
									}else if(isValid || value === ""){
										onFieldChange("ad_compliance_tsn", numericValue);
                                        setError({...error,ad_compliance_tsn:regexConstants.numberWithDot.test(numericValue) || e.target.value  === (null || undefined || "") ? "" : e.target.value  === null  ? "" : ""})
									}
								}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        AD Compliance CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 99999</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                name="ad_compliance_csn"
                                id="ad_compliance_csn"
                                fullWidth
                                margin='normal'
                                inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data?.ad_compliance_csn) ? 8 : 6 }}
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                error={error.ad_compliance_csn ? true : false}
                                helperText={error.ad_compliance_csn ? error.ad_compliance_csn : ""}
                                value={regexConstants.alphanumespcespclchar.test(data?.ad_compliance_csn) ? data?.ad_compliance_csn : format_Commas(data?.ad_compliance_csn.toString().replace(/[^0-9.]/g, ''))}
                                onChange={(e) => {
									if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
										onFieldChange("ad_compliance_csn", e.target.value);
                                        setError({...error,ad_compliance_csn:e.target?.value  === null  || e.target?.value === ""  ? "" : !specificKey.includes(e.target.value.toUpperCase()) && specificMessCsn})

									} else {
										e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && onFieldChange('ad_compliance_csn', e.target.value)
                                        setError({ ...error, ad_compliance_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : ""})
									}
								}
								}
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Type"
                                id="type"
                                fullWidth
                                margin='normal'
                                inputProps={{maxLength:200}}
                                required={!smbcInstance}
                                InputLabelProps={{ shrink: true }}
                                error={error?.type ? true : false}
                                helperText={error?.type ? error?.type : ""}
                                value={data?.type}
                                onChange={(e) => { onFieldChange("type", e.target.value); onRestErrorKey("type") }}

                            />
                        </Grid>
                        {smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Reference"
                                name="reference"
                                fullWidth
                                inputProps={{maxLength:256}}
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data?.reference}
                                onChange={(e) => onFieldChange("reference", e.target.value)}
                            />
                        </Grid> : null}
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Date of compliance"
                                    // label={<> Date of compliance {data?.ad_compliance_status !== "COMPLIED" ? null : (smbcInstance ? null : <span style={{ color: "red" }}> *</span>)} </>}
                                    id="ad_compliance_date"
                                    format={dayMonthDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin='normal'
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    error={error?.ad_compliance_date ? true : false}
                                    helperText={error?.ad_compliance_date ? error?.ad_compliance_date : ""}
                                    value={data?.ad_compliance_date || null}
                                    onPaste={(event) => event.preventDefault()}
                                    onChange={(data) => { onFieldChange("ad_compliance_date", moment(data).format(backendDateFormat)); onRestErrorKey("ad_compliance_date") }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Reference Documents"
                                id="ref_documents"
                                margin='normal'
                                required={!smbcInstance}
                                error={error?.ref_documents ? true : false}
                                helperText={error?.ref_documents ? error?.ref_documents : ""}
                                multiline
                                fullWidth
                                minRows={5}
                                inputProps={{maxLength:200}}
                                InputLabelProps={{ shrink: true }}
                                value={data?.ref_documents}
                                onChange={(e) => { onFieldChange("ref_documents", e.target.value); onRestErrorKey("ref_documents") }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Workorder"
                                id="workorder"
                                margin='normal'
                                multiline
                                fullWidth
                                inputProps={{maxLength:500}}
                                InputLabelProps={{ shrink: true }}
                                required={!smbcInstance}
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                error={error?.workorder ? true : false}
                                helperText={error?.workorder ? error?.workorder : ""}
                                value={data?.workorder}
                                onChange={(e) => { onFieldChange("workorder", e.target.value); onRestErrorKey("workorder") }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">AD Compliance DFP {smbcInstance ? null : <span style={{ color: "red" }}> *</span>}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="ad_compliance_dfp"
                                    name="ad_compliance_dfp"
                                    defaultValue="top"
                                    value={data?.ad_compliance_dfp}
                                    onChange={(e) => { onFieldChange("ad_compliance_dfp", e.target.value); onRestErrorKey("ad_compliance_dfp") }}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error?.ad_compliance_dfp && (
                                    <span style={{ color: "red", fontSize: "0.75rem" }}>
                                        {error?.ad_compliance_dfp}
                                    </span>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">Release certificate</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="release_certificate"
                                    name="release_certificate"
                                    defaultValue="top"
                                    value={data?.release_certificate}
                                    onChange={(e) => onFieldChange("release_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">Certificate of Conformity</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="conformity_crt"
                                    name="conformity_crt"
                                    defaultValue="top"
                                    value={data?.conformity_crt}
                                    onChange={(e) => onFieldChange("conformity_crt", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} margin='normal' style={{ marginTop: "10px" }}>
                            <div className="upload-amp-textbox" style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px" }}>
                                <span onClick={() => setUploadFile(true)} style={{ cursor: 'valueer' }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <div style={{ overflow: "auto" }}>
                                    {data?.attachments?.length > 0 && data?.attachments?.map((attachments) =>
                                        <>{attachments.name}
                                            <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                            <CloseIcon onClick={() => onRemoveUploadFile(attachments)} color='secondary' style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {isLoading ? <PageLoader /> : null}
            {
                uploadFile ?
                    <Dialog
                        open={true}
                        onClose={() => setUploadFile(false)}
                        className='camo-management-modal'
                        maxWidth={'md'}
                    >
                        <DialogTitle id="scroll-dialog-title">
                            Uplaod AD  File
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <div style={{ width: '380px' }}>
                                <div>
                                    <DropzoneArea
                                        filesLimit={5}
                                        maxFileSize={104857600}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview={true}
                                        showPreviews={true}
                                        dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['info', 'error']}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                        clearOnUnmount={true}
                                        onChange={(value) => onFieldChange("attachments", value)}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} onClick={() => setUploadFile(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                            <Button onClick={() => setUploadFile(false)} color='primary' size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={24} color='white' /> : 'Save'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null

            }
        </>
    )
}
export default AddEditFleetAD;