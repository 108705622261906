import React, { Fragment, Component } from "react";
import { isMobile } from "react-device-detect";
import { getLocalStorageInfo, getGlobalCookie, authorizeAutoLogin, setGlobalCookie, } from "../utils";
import { DesktopHeader, MobileHeader, DesktopSideNav, MntSideNav, MobileSecMenu, PageLoader, } from "../shared_elements";
import { connect } from "react-redux";
import config from "../config";
import * as actions from "../shared_elements/actions";
import { getAllAssetListAc } from "../shared_elements/actionCreators";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid } from "@material-ui/core";

window.addEventListener("visibilitychange", checkLogin);
function checkLogin() {
  if (document.visibilityState || document.hasFocus) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let location = window.location.href;
    let lessorAccess = JSON.parse(getGlobalCookie("lessorAccess"));
    if ( !window.location.href.includes("login") && !window.location.href.includes("select-lessor") ) {
      setGlobalCookie("redirectURIRecords", window.location.href, 3);
    }
    if (lessorAccess === undefined || lessorAccess === null) {
      localStorage.clear();
      if ( !location.includes("login") && !location.includes("shared-data") && !location.includes("records/preview") ) {
        window.location.reload();
      }
    } else {
      if (config.api.networkInterface.includes(lessorAccess.environment)) {
        if (!getLocalStorageInfo()) {
          authorizeAutoLogin({ lessor_id: lessorAccess.id });
        } else if (!location.includes("select-lessor")) {
          if ( lessorAccess.id !== userInfo.defaultLessor.id || getLocalStorageInfo().access !== lessorAccess.access ) {
            authorizeAutoLogin({ lessor_id: lessorAccess.id });
          } else {
            //window.location.assign('/')
          }
        }
      } else {
        if (userInfo && userInfo.defaultLessor == undefined) {
          // window.location.assign("/select-lessor");
        }
      }
    }
    // getLessorsList()
  }
}
export default function PageLayout(HocComponent, extraProps = {}) {
  class Layout extends Component {
    state = {
      filterAssets: [
        {
          id: 1,
        },
      ],
      // sidebarOpen: true,
    };
    constructor(props) {
      super(props);
      this.state = {
        sidebarOpen: true,
      };
      this.toggleSidebar = this.toggleSidebar.bind(this);
    }
    layoutMarginProps = () => {
      if (extraProps.layoutPhase) {
        switch (extraProps.layoutPhase) {
          case 0:
            // Desktop with Primary Header Only
            return "50px 0 0 0";
          case 1:
            return "45px 0 0 77px";
          case 2:
            return "70px 0 0 272px";
          default:
            return "0";
        }
      } else {
        return "0";
      }
    };
    componentDidMount() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let lessorAccess = JSON.parse(getGlobalCookie("lessorAccess"));
      if (config.api.networkInterface.includes(lessorAccess.environment)) {
        if (!getLocalStorageInfo()) {
          authorizeAutoLogin({ lessor_id: lessorAccess.id });
        } else if (lessorAccess.id !== userInfo.defaultLessor.id) {
          authorizeAutoLogin({ lessor_id: lessorAccess.id });
        }
      }
    }
    toggleSidebar() {
      this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }
    render() {
      const fileIcons = this.props.pltConstants.filter(
        (icon) => icon.type === "file_extension_drive"
      );
      const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532 )
      if (getLocalStorageInfo()) {
        return (
          <section>
            <Fragment>
              {isMobile ? (
                <>
                <MobileHeader />
                <Grid container>
                <>
                 { extraProps.layoutPhase == 2 && <>
                 <Grid item xs={this.state.sidebarOpen ? 2 : 0} >
                    <div>
                      <div className="secondary-sidenav-bg" style={{left:"0px"}}>
                        <div className="sidebar-icon">
                          <button
                            className="menu-button btn btn-primary"
                            onClick={this.toggleSidebar}
                          >
                            {this.state.sidebarOpen ? (
                              <CloseIcon />
                            ) : (
                              <MenuIcon />
                            )}
                          </button>
                        </div>
                        <div
                          className={`secondary-sidenav pt-0 ${
                            this.state.sidebarOpen ? "d-block" : "d-none"
                          }`}
                        >
                          <MntSideNav />
                        </div>
                      </div>
                    </div>
                  </Grid></>}
                  <Grid xs={this.state.sidebarOpen ? 10 : 12} style={{maxWidth: extraProps.layoutPhase === 2 ? " " : "110%",marginBottom:'50px'}}>
                    <main
                      // className={ this.state.sidebarOpen ? "sideOpen" : "sideClosed" }
                      // style={{ padding: isMobile ? "15px 15px 50px 15px" : "10px 20px 50px 20px", background: "white", }}
                      style={{marginLeft:extraProps.layoutPhase === 2 ? "50px" : "20px",padding:"15px 15px 50px 15px",marginBottom:'20px'}}
                    >
                      <div>
                        <HocComponent {...this.props} />
                      </div>
                    </main>
                  </Grid>
                </>
              </Grid>
                </>
              ) : (
                <Fragment>
                  <DesktopHeader />
                  <DesktopSideNav />
                </Fragment>
              )}
            </Fragment>
            {isMobile ? (
              <MobileSecMenu extraProps={extraProps} />
            ) : extraProps.layoutPhase === 2 ? (
              <Grid className="mt-0 w-100" container>
                <>
                  <Grid item xs={this.state.sidebarOpen ? 2 : 0} >
                    <div>
                      <div className="secondary-sidenav-bg" style={{width:smbcInstance && this.state.sidebarOpen && "225px"}}>
                        <div className="sidebar-icon">
                          <button
                            className="menu-button btn btn-primary"
                            onClick={this.toggleSidebar}
                          >
                            {this.state.sidebarOpen ? (
                              <CloseIcon />
                            ) : (
                              <MenuIcon />
                            )}
                          </button>
                        </div>
                        <div
                          className={`secondary-sidenav pt-0 ${
                            this.state.sidebarOpen ? "d-block" : "d-none"
                          }`}
                        >
                          <MntSideNav />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={this.state.sidebarOpen ? 10 : 12}>
                    <main
                      className={
                        this.state.sidebarOpen ? "sideOpen" : "sideClosed"
                      }
                      style={{
                        padding: isMobile
                          ? "15px 15px 50px 15px"
                          : "10px 20px 50px 20px",
                        background: "white",
                      }}
                    >
                      <div>
                        <HocComponent {...this.props} />
                      </div>
                    </main>
                  </Grid>
                </>
              </Grid>
            ) : (
              <main
                style={{
                  margin: isMobile ? "10px 0 0 0" : this.layoutMarginProps(),
                  padding: isMobile
                    ? "15px 15px 50px 15px"
                    : "20px 20px 10px 20px",
                  background: "white",
                  // height: "100vh",
                }}
              >
                <div>
                  <HocComponent {...this.props} />
                </div>
              </main>
            )}
          </section>
        );
      } else {
        return <PageLoader />;
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
    allAssets: state.shareReducer.allAssets,
  });
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      getAllAssetListFn: () => dispatch(getAllAssetListAc()),
      toggleTheAppSwitcher: (flag) =>
        dispatch({ type: actions.APPS_SWITCHER, payload: flag }),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(Layout);
}
