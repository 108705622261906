import React, { useState } from 'react'
import { CircularProgress, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { PageLoader } from '../../../shared_elements';
import { useSnackbar } from 'notistack';
import {globalFileUploadService } from '../../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../../utils';


function UploadAttachements({ toggleModalFn, title, moduleType ,data}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [file, setFile] = useState([]);
    const [isLoading, setLoading] = useState(false)

	const importFile = () => {
        var formData = new FormData();
        formData.append(
        'file',file[0],
        "file_type", file[0].type,
        "file_name",file[0].name,
        "module_id", data?.id,
        "module_type_id",14,
        "asset_type_id",2
        )
		setLoading(true);
		globalFileUploadService(`camo/attachment/upload/?fleet=${moduleType}`, formData)
			.then(response => {
				setLoading(false); toggleModalFn();
				if (checkApiStatus(response)) {
					enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}

    return (
        <Dialog
            open={true}
            onClose={toggleModalFn}
            className='camo-management-modal'
            maxWidth={'md'}
        >
            <DialogTitle id="scroll-dialog-title">
                {title ? title : "Import File"}
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '380px' }}>
                    <div>
                        <DropzoneArea
                            filesLimit={1}
                            maxFileSize={104857600}
                            acceptedFiles={['.xlsx']}
                            showPreviewsInDropzone={false}
                            useChipsForPreview={true}
                            showPreviews={true}
                            dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                            dropzoneClass="drag-drop-cnt"
                            maxWidth="sm"
                            showAlerts={['info', 'error']}
                            alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                            clearOnUnmount={true}
                            onChange={(file) => setFile(file)}
                        />
                    </div>
                </div>
                {/* <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension only ('.xlsx').</span> */}
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading || !file.length}  onClick={importFile} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} color='white' /> : 'Save'}
                </Button>
            </DialogActions>
            {isLoading ? <PageLoader /> : null}
        </Dialog>

    )
}
export default UploadAttachements;