import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Dialog, TableRow, TableCell, Tooltip, IconButton, } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import ConfirmDialog from "../components/ConfirmDialog"
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments"
import { dayMonthDateFormat } from "../../../../constants"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import SrTaskForm from "./SrTaskForm"
import moment from "moment"
import { fillEditFromApi } from "../apiService"
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory"
import { permissionCheckFnforCamo, checkApiStatus } from "../../../../utils"
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices"

const SrList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, headerTsn, headerCsn, uuid, last_used_folder_uuid, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, getResponseBack, checkbox_last_used_folder_name,assetsDetail }) => {
	const [open, setOpen] = useState(false)
	const [openEditForm, setEditForm] = useState(false)
	const [editFormId, setEditFormId] = useState(null)
	const [editFormData, setEditFormData] = useState(null)
	const [formTitle, setFormTitle] = useState("")
	const [attachments, setAttachments] = useState(null)
	const [showFullDescription, setShowFullDescription] = useState({})
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
		useState(null)
	const [moduleId, setmoduleId] = useState(null)

	const handleClick = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	let todayDate = new Date()
	const remainingValuesDate =
		item.interval_D -
		Math.round(
			(todayDate - new Date(Date.parse(item.lastDone_D))) /
			(1000 * 60 * 60 * 24)
		)

	const closeAddForm = () => {
		setEditForm(false)
	}

	const handleEditRow = () => {
		fillEditFromApi(item, props).then((response) => {
			setEditFormData(response.data.data)
			setEditFormId(response.data.data.id)
			setAttachments(response.data.data.attachments)
			setFormTitle("Update Structure Repairs")
			setEditForm(true)
		})
	}
	const downAllAttach = () => {
		fillEditFromApi(item, props).then((response) => {
			setmoduleId(response.data.data.id)
		})
		getAttachements()
	}
	const getAttachements = () => {
		const matchingItem = assetsDetail?.list?.find(items => items?.id === item.id);
		if (!matchingItem) return [];
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; 
			if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...files, ...folders])
			} else if (files?.length > 0 && folders?.length > 0) {
				getAttachments([...folders, ...files])
			} else if (attachments?.length > 0 && files?.length > 0) {
				getAttachments([...attachments, ...files])
			} else if (attachments?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...folders])
			} else if (attachments?.length > 0) {
				getAttachments([...attachments])
			} else if (folders?.length > 0) {
				getAttachments([...folders])
			} else if (files?.length > 0) {
				getAttachments([...files])
			}
		}
		return [];

	};

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	}

	const formatDate = (date) => {
		return moment(date).format("DD-MM-YYYY")
	}

	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		})
	}

	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments)
		setAttachmentsDialogue(true)
	}

	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false)
	}

	const convertToDate = (value) => {
		if (typeof value === "string") {
			return formatDate(value)
		} else if (typeof value === "number") {
			return value
		} else {
			return "--"
		}
	}
	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const srurl = `audit/camo/repairlist/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`

	const removeAttachment = (item, checklist, fileId, uuid) => {
		const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
		const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
		if (uuid) {
			let payload = {};
			payload.delete = true;
			payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
			globalPutService(`camo/repair/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillEditFromApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillEditFromApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				}
			});
		}
	};

	function getAttachmentCount(item) {
		const { attachments, checklist } = item;
		const { files, folders } = checklist || {};

		const attachmentCount = attachments?.length || 0;
		const fileCount = files?.length || 0;
		const folderCount = folders?.length || 0;

		if (attachmentCount && fileCount && folderCount) {
			return attachmentCount + fileCount + folderCount;
		} else if (fileCount && folderCount) {
			return fileCount + folderCount;
		} else if (attachmentCount && folderCount) {
			return attachmentCount + folderCount;
		} else if (attachmentCount && fileCount) {
			return attachmentCount + fileCount;
		} else {
			return folderCount || fileCount || attachmentCount || '--';
		}
	}
	const attachmentCount = getAttachmentCount(item);

	return (
		<>
			<TableRow
				hover
				tabIndex={-1}
				style={{ cursor: "pointer" }}
				className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"}
			>
				<TableCell>
					{permissionCheckFnforCamo({ primaryKey: "repair", keyIndex: "U", }) ? (
						<Tooltip title="Edit">
							<IconButton
								className="edit-icon"
								onClick={(e) => {
									handleEditRow(e)
								}}
							>
								<CreateOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					{permissionCheckFnforCamo({ primaryKey: "repair", keyIndex: "D", }) ||
						permissionCheckFnforCamo({ primaryKey: "repair", keyIndex: "OD", userId: item.created_by, }) ? (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					<RevisionHistory buttonType={true} url={srurl} />
				</TableCell>
				<TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
				<TableCell> {item.repair_ref_number ? item.repair_ref_number : "--"} </TableCell>
				<TableCell>{item.repair_type ? item.repair_type : "--"}</TableCell>
				<TableCell style={{ width: "200px", textAlign: "left" }}>
					{item.repair_description && item.repair_description.length > 150 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span>
									{item.repair_description}
									<a
										href="#"
										style={{ color: "#0e7fe1" }}
										onClick={(e) => {
											e.preventDefault()
											toggleDescription(item.id)
										}}
									>
										..less
									</a>
								</span>
							) : (
								<span>
									{item.repair_description.substring(0, 150)}
									<a
										href="#"
										style={{ color: "#0e7fe1" }}
										onClick={(e) => {
											e.preventDefault()
											toggleDescription(item.id)
										}}
									>
										..more
									</a>
								</span>
							)}
						</div>
					) : item.repair_description &&
						item.repair_description.length <= 150 ? (
						<span>{item.repair_description}</span>
					) : (
						"--"
					)}
				</TableCell>

				<TableCell> {item.repeat_inspection ? item.repeat_inspection : "--"} </TableCell>
				<TableCell> {item.repair_location ? item.repair_location : "--"} </TableCell>
				<TableCell>{item.repair_detail ? item.repair_detail : "--"}</TableCell>
				<TableCell> {item.repair_compliance_ref ? item.repair_compliance_ref : "--"} </TableCell>
				<TableCell> {item.repair_compliance_date ? moment(item.repair_compliance_date).format(dayMonthDateFormat) : "--"} </TableCell>
				<TableCell> {item.repair_compliance_tsn ? item.repair_compliance_tsn : "--"} </TableCell>
				<TableCell> {item.repair_compliance_csn ? item.repair_compliance_csn : "--"} </TableCell>
				<TableCell>{convertToDate(item?.remaining?.due_at)}</TableCell>
				<TableCell> {item.last_done_date ? moment(item.last_done_date).format(dayMonthDateFormat) : "--"} </TableCell>
				<TableCell>{item.last_done_fh ? item.last_done_fh : "--"}</TableCell>
				<TableCell>{item.last_done_fc ? item.last_done_fc : "--"}</TableCell>
				<TableCell style={{ width: "200px", textAlign: "left" }}>
					{item.remarks && item.remarks.length > 150 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span>
									{item.remarks}
									<a
										href="#"
										style={{ color: "#0e7fe1" }}
										onClick={(e) => {
											e.preventDefault()
											toggleDescription(item.id)
										}}
									>
										..less
									</a>
								</span>
							) : (
								<span>
									{item.remarks.substring(0, 150)}
									<a
										href="#"
										style={{ color: "#0e7fe1" }}
										onClick={(e) => {
											e.preventDefault()
											toggleDescription(item.id)
										}}
									>
										..more
									</a>
								</span>
							)}
						</div>
					) : item.remarks && item.remarks.length <= 150 ? (
						<span>{item.remarks}</span>
					) : (
						"--"
					)}
				</TableCell>
				<TableCell>
					{attachmentCount ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => downAllAttach()} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>

			{/* edit form dialog */}
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
			>
				<SrTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					uuid={uuid}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>
			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "SR", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					item={item}
					removeAttachment={removeAttachment}
				/>
			</Dialog>
		</>
	)
}
export default withRouter(SrList)
