import { React, useState, useEffect, Fragment } from 'react'
import { Table, TableBody, Paper, Button, Grid, TextField, Tooltip, InputAdornment, TablePagination, Dialog } from "@material-ui/core";
import { globalGetService, globalDeleteService, globalExportService } from '../../../utils/globalApiServices';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import config from "../../../config"
import { DownloadAllApi } from './apiServices';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { FleetAdListing, FleetAdTableHeader, AddEditFleetAD } from '../components';
import { downloadFileType, checkApiStatus, getLocalStorageInfo } from '../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp, ExportMenu } from '../../../shared_elements';
import FilterComponent from '../../../shared_elements/components/filter_component';
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { adFilterOptions, adSmbcFilterOptions } from './index';
import ShowAttachments from '../../../shared_elements/components/ShowAttachments';
import ImportFormat from '../../../shared_elements/components/ImportFormat';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';

function FleetMainAd({ match }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isLoading, setLoading] = useState(false)
	const [skeltonLoader, setSkeltonLoader] = useState(false)
	const [deletelist, setDeleteList] = useState({ modal: false, data: null })
	const [fleetAdListing, setFleetAdListing] = useState({ list: [], pagination: {} })
	const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
	const [importFile, setImportFile] = useState({ modal: false, data: null })
	const [addEditFleetAD, setAddEditFleetAD] = useState({ modal: false, data: null, type: '' })
	const [filter, setFilter] = useState()
	const [applicability, setApplicability] = useState([])
	const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })

	useEffect(() => {
		getFleetAdListing({ per_page: 10, asset_type: 2 }, 'skeltonLoader')
		getApplicability()
	}, [])
	const getFleetAdListing = (query, loaderType) => {
		loaderType === "skeltonLoader" ? setSkeltonLoader(true) : setLoading(true)
		globalGetService(`camo/fleet_ad/`, query)
			.then((response) => {
				loaderType === "skeltonLoader" ? setSkeltonLoader(false) : setLoading(false)
				if (response.status === 200) {
					setFleetAdListing(response.data)
					setFilter(query)
				}
			})
	}

	const deleteFleetADList = () => {
		setLoading(true)
		globalDeleteService(`camo/fleet_ad/${deletelist?.data?.id}/`)
			.then((response) => {
				setLoading(false); setDeleteList({ modal: false }); getFleetAdListing({ per_page: 10, asset_type: 2 })
				if (checkApiStatus(response)) {
					enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}

			})
	}
	const exportADListing = (extension) => {
		const date = new Date()
		const todayDate = moment(String(date)).format("DD-MM-YYYY")
		setLoading(true);
		globalExportService(`/camo/fleet_ad/2/export/`, { download: extension })
			.then(response => {
				setLoading(false);
				downloadFileType(response.data, `fleet_Ad_${todayDate}`, extension)
				enqueueSnackbar("Download fleet AD List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
			});
	}

	const createSortHandler = (key) => {
		let sortQuery = { sort: key };
		if (sortInfo.sort === key) {
			sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
		} else {
			sortQuery = { ...sortQuery, sort_by: 'asc' }
		}
		setSortInfo(sortQuery)
		getFleetAdListing({ ...sortQuery, per_page: 10, asset_type: 2 }, 'pageLoader');
	}
	const getApplicability = () => {
		globalGetService(`console/engine-types/`)
			.then((response) => {
				if (response.status === 200) {
					setApplicability(response?.data?.data?.engineTypes)
				}
			})
	}

	const deleteAttachements = (files, items, id) => {
		globalDeleteService(`camo/attachment/${id}/delete/?fleet=ad`)
			.then((response) => {
				if (response.data?.statusCode === 200) {
					getFleetAdListing({ per_page: 10, asset_type: 2 }, 'pageLoader')
					enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

				}
			})

	}
	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	};

	const filterObj = Object.assign({}, filter, delete filter?.per_page, delete filter?.sort, delete filter?.sort_by, delete filter?.asset_type)
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	let filterOptions = Object.assign({}, smbcInstance ? adSmbcFilterOptions : adFilterOptions)
	filterOptions = {
		...filterOptions,
		applicability: {
			...filterOptions.applicability,
			options: applicability
		},
	}

	return (
		<section className='camo-fleet-module'>
			<DeploymentMessage/>
			{skeltonLoader ? <STableLoader count={7} /> : <>
				<Paper square variant='outlined' style={{ padding: '0px 5px', marginBottom: "8px" }}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={3} md={3}>
							<TextField
								variant='outlined'
								fullWidth
								margin='normal'
								size='small'
								placeholder='Search ATA Chapter, AD Reference Number, AD Issuing Authority, AD Description'
								onChange={(e) => getFleetAdListing({ search: e.target.value, asset_type: 2, per_page: 10 }, 'pageloader')}
								InputProps={{ style: { fontSize: 14, }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<div style={{ marginTop: "12px" }}>
								<FilterComponent
									filter={filterObj}
									filterMenu={filterOptions}
									getResponseBack={(applyFilter) => getFleetAdListing({ ...applyFilter, page: 1, per_page: 10, asset_type: 2 }, 'pageLoader')}
								/>
							</div>
						</Grid>
						<Grid item xs={12} md={3}>
							<ul className='list-inline' style={{ float: "right", margin: "revert", }}>
								<li className='list-inline-item' style={{ marginRight: "5px" }}>
									<Button variant='contained' color='primary' size='small' onClick={() => setAddEditFleetAD({ modal: true, data: null, type: 'add' })}> + Add AD</Button>
								</li>
								<li className='list-inline-item' style={{ marginRight: "5px" }}>
									<Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
								</li>
								<li className='list-inline-item'>
									<ExportMenu
										exportReportFn={(file) => exportADListing(file.extension)}
										files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
									/>
								</li>
							</ul>
						</Grid>
					</Grid>
				</Paper>
				<Paper variant='outlined' square style={{ padding: '5px 10px', marginBottom: "5px" }}>
					<div style={{ height: `${window.innerHeight - 220}px`, overflow: 'auto' }}>
						<Table className='mui-table-format' stickyHeader={true}>
							<FleetAdTableHeader
								createSortHandler={(sort) => createSortHandler(sort)}
								sortInfo={sortInfo}
							/>
							<TableBody className='ad-fleet-listing'>
								{fleetAdListing?.list?.map((item, index) =>
									<FleetAdListing
										item={item}
										key={index}
										toggleModalFn={() => setAddEditFleetAD({ modal: true, data: item, type: 'edit' })}
										toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
										fleetData={addEditFleetAD.data}
										handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
									/>
								)}
							</TableBody>
						</Table>
						{!fleetAdListing?.list?.length ? <EmptyCollection title="No Records Founds" /> : null}
					</div>
					<TablePagination
						rowsPerPageOptions={[5, 10, 20, 50, 100]}
						component="div"
						count={fleetAdListing?.pagination?.total}
						rowsPerPage={parseInt(fleetAdListing?.pagination?.per_page)}
						page={fleetAdListing?.pagination?.page - 1}
						backIconButtonProps={{ "aria-label": "previous page" }}
						nextIconButtonProps={{ "aria-label": "next page" }}
						onChangePage={(event, newPage) => getFleetAdListing({ per_page: 10, page: newPage + 1, asset_type: 2 }, 'pageLoader')}
						onChangeRowsPerPage={(event) => getFleetAdListing({ per_page: event.target.value, asset_type: 2 }, "pageLoader")}
					/>
				</Paper>
			</>}
			{
				addEditFleetAD.modal ?
					<AddEditFleetAD
						fleetData={addEditFleetAD.data}
						modeType={addEditFleetAD.type}
						toggleModalFn={() => setAddEditFleetAD({ modal: false })}
						getResponseBack={() => getFleetAdListing({ per_page: 10, asset_type: 2 })}
					/> : null
			}
			{
				deletelist.modal ?
					<DeletePopUp
						title='Delete Fleet AD'
						deleteRecordFn={() => deleteFleetADList()}
						modal={() => setDeleteList({ modal: true })}
						content='Do you really want to delete this record?'
						toggleModalFn={() => setDeleteList({ modal: false })}

					/> : null

			}
			{importFile.modal ?
				<ImportFormat
					title="Import Fleet AD"
					moduleType='fleet_ad'
					fleet={true}
					moduleName="SampleFile Fleet Ad"
					getResponseBack={() => getFleetAdListing({ per_page: 20, asset_type: 2 })}
					toggleModalFn={() => setImportFile({ modal: false })}
				/> : null
			}
			{
				attchementsModal.modal ?
					<Dialog
						open={true}
						onClose={() => setAttchementsModal({ modal: false, data: null })}
						id="htDialog"
					>
						<ShowAttachments
							handleClose={() => setAttchementsModal({ modal: false, data: null })}
							handleShowAttachments={fleetAdListing?.list.filter(item => item?.id === attchementsModal?.data?.id).map(item => item?.attachments)[0]}
							openAttachment={openAttachment}
							files={{ title: "AD", extension: "zip", key: "" }}
							downloadAllApi={() => DownloadAllApi(attchementsModal.data, 'AD')}
							item={attchementsModal.data}
							removeAttachment={deleteAttachements}
						/>
					</Dialog>
					:
					null
			}
			{isLoading ? <PageLoader /> : null}
		</section >
	)
}

export default withRouter(FleetMainAd)