import { React } from "react"
import { TableCell, TableHead, TableRow ,TableSortLabel} from "@material-ui/core"
import config from "../../../config"
import { getLocalStorageInfo } from "../../../utils"

const FleetSbTableHeader = ({ sortInfo, createSortHandler }) => {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	return (
		<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} className="fleet-ad-header">
			{smbcInstance ? null : <TableRow>
				<TableCell colSpan={10} align="center"></TableCell>
				<TableCell colSpan={3} align="center">SB Compliance Details</TableCell>
				<TableCell colSpan={1} align="center"></TableCell>
			</TableRow>}
			{smbcInstance ?
				<TableRow>
					<TableCell>Action </TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_ref_number' ? true : false} direction={sortInfo.sort === 'sb_ref_number' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_ref_number')} >
							SB Reference Number
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_category' ? true : false} direction={sortInfo.sort === 'sb_category' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_category')} >
							Category
						</TableSortLabel>
					</TableCell>
					<TableCell className="sb-des-list-style">SB Description </TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_applicability' ? true : false} direction={sortInfo.sort === 'sb_applicability' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_applicability')} >
							SB Applicability
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_compliance_status' ? true : false} direction={sortInfo.sort === 'sb_compliance_status' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_compliance_status')} >
							SB Compliance Status
						</TableSortLabel>
					</TableCell>
					<TableCell>TSN</TableCell>
					<TableCell>CSN </TableCell>
					<TableCell>Date of Compliance </TableCell>
					<TableCell>Remarks</TableCell>
					<TableCell>Attachments </TableCell>
				</TableRow> :
				<TableRow>
					<TableCell>Action </TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'ata_chapter' ? true : false} direction={sortInfo.sort === 'ata_chapter' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('ata_chapter')} >
							ATA Chapter
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_ref_number' ? true : false} direction={sortInfo.sort === 'sb_ref_number' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_ref_number')} >
							SB Reference Number
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style"> SB Issued by </TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_applicability' ? true : false} direction={sortInfo.sort === 'sb_applicability' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_applicability')} >
							SB Applicability
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style">SB Description </TableCell>
					<TableCell>
						<TableSortLabel active={sortInfo.sort === 'type' ? true : false} direction={sortInfo.sort === 'type' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('type')} >
							SB type
						</TableSortLabel>
					</TableCell>
					<TableCell className="ad-list-style">SB Effectivity </TableCell>
					<TableCell>SB Effective Date </TableCell>
					<TableCell className="ad-list-style">
						<TableSortLabel active={sortInfo.sort === 'sb_compliance_status' ? true : false} direction={sortInfo.sort === 'sb_compliance_status' ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler('sb_compliance_status')} >
							SB Compliance Status
						</TableSortLabel>
					</TableCell>
					<TableCell>TSN	 </TableCell>
					<TableCell>CSN </TableCell>
					<TableCell>Date of Compliance </TableCell>
					<TableCell>Attachments </TableCell>
				</TableRow>
			}
		</TableHead>
	)
}

export default FleetSbTableHeader