import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from 'moment';
import config from "../../../config"
import { regexConstants } from "../../../constants/regEx";
import { dayMonthDateFormat } from "../../../constants";
import { getLocalStorageInfo,Comma_format } from '../../../utils';
import RevisionHistory from '../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";

const FleetSbListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)

	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const fleet_SbUrl = `audit/camo/fleetsbstatus/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`

	return (
		<>
			<TableRow>
				<TableCell >
					<Tooltip title="Edit" placement='top'>
						<IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} >
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete" placement='top' >
						<IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}>
							<DeleteOutlinedIcon />
						</IconButton>
					</Tooltip>
					<RevisionHistory buttonType={true} url={fleet_SbUrl} />
				</TableCell>
				{smbcInstance ? null : <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>}
				<TableCell>{item.sb_ref_number ? item.sb_ref_number : "--"}</TableCell>
				{smbcInstance ? <TableCell>{item.sb_category ? item.sb_category : "--"}</TableCell> : null}
				{smbcInstance && <TableCell style={{ width: "200px", textAlign: "left" }}> {item.description} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.sb_issued_by ? item.sb_issued_by : "--"}</TableCell>}
				<TableCell>{item?.sb_applicability?.map((item) => item?.name) || '--'}</TableCell>
				{!smbcInstance && <TableCell style={{ width: "200px", textAlign: "left" }}> {item.description} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.type ? item.type : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell> {item.effectivity_date ? moment(item.effectivity_date).format(dayMonthDateFormat) : "--"} </TableCell>}
				<TableCell> {item.sb_compliance_status ? item.sb_compliance_status : "--"} </TableCell>
				<TableCell> {item.sb_compliance_tsn ?  regexConstants.alphanumespcespclchar.test(item.sb_compliance_tsn) ? item.sb_compliance_tsn :  Comma_format(parseInt(item.sb_compliance_tsn)) : "--"} </TableCell>
				<TableCell> {item.sb_compliance_csn ?  regexConstants.alphanumespcespclchar.test(item.sb_compliance_csn) ? item.sb_compliance_csn :  Comma_format(parseInt(item.sb_compliance_csn)) : "--"} </TableCell>
				<TableCell> {item.sb_compliance_date ? moment(item.sb_compliance_date).format(dayMonthDateFormat) : "--"} </TableCell>
				{smbcInstance ? <TableCell> {item.remark ? item.remark : "--"} </TableCell> : null}
				<TableCell>
					{item.attachments?.length > 0 ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" >
									<AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
									<a href="#" style={{ color: "#0e7fe1" }}>{item.attachments?.length > 0 && item.attachments?.length}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
		</>
	)
}
export default FleetSbListing